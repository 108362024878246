export enum Partner {
  None = "manifest",
  Revive = "revive",
  Altrua = "altrua",
  WestGate = "westgate",
  Nextran = "nextran",
  Sunstate = "sunstate",
  Synergy =  "synergy",
  FirstPrimaryCare = "firstprimarycare",
  Healthyr = "healthyr",
  Indipop = "indipop",
  RX4Free = "rx4free",
  BettyHealth = "bettyhealth",
  Doctegrity = "doctegrity",
  ClearPrice = "clearprice",
  MyDPCrx = "mydpcrx",
  Upfront = "upfront",
  WellClarity = "wellclarity",
  Strada = "strada",
}

function setLogoUrl(token: string, width: number, partner: Partner): string {
  if (process.env.NODE_ENV === "production") {
    return process.env.API_HOSTNAME + `/member-card/${partner}/${token}?width=${width}`;
  } else {
    return `http://localhost:9999/member-card/${partner}/${token}?width=${width}`;
  }
}

interface Props {
  token: string;
  width: number;
  className?: string;
  partner: Partner;
}

export default function DiscountCard({
  token,
  width,
  className,
  partner,
}: Props) {
  return (
    <img
      className={className}
      src={setLogoUrl(token, width, partner)}
      alt="discount card for member"
    />
  );
}
