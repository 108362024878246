export type SearchOption = {
  displayName: string;
  ndc: string;
  seoName: string;
};

export function handleSelectSearch(option: SearchOption) {
  const encoded = btoa(JSON.stringify(option));
  document.location = `/search/?query=${encoded}`;
}
