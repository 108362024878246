import { render } from "preact";
import Search from "./Search";
import { handleSelectSearch } from "./searchOption";

console.log(process.env.API_HOSTNAME);

render(
  <Search onSelect={handleSelectSearch} />,
  document.querySelector("div#search-box"),
);
