import { useMemo, useEffect, useState } from "preact/hooks";
import { injectGlobal } from "@emotion/css";
import styled from "@emotion/styled";
import fetch from "unfetch";
import create from "zustand";
import debounce from "lodash/debounce";
import { devtools } from "zustand/middleware";
import { immer } from "../store/middleware";
import { Result, Ok, Err } from "ts-results";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { ThemeProvider } from "@material-ui/styles";
import { InvertedButton } from "../shared/Button";
import theme from "../shared/theme";
import { API_URL } from "../shared/variables";
import getPartner from "../discount-card/getPartner";
import checkSpanish from "../discount-card/checkSpanish";
import { SearchOption } from "./searchOption";
import { GraphQLClient, gql } from "graphql-request";

interface SearchState {
  text: string;
  loading: boolean;
  options?: Result<SearchOption[], Error>;
  updateQuery: (text: string) => void;
  refreshOptions: () => Promise<void>;
}

function searchButtonColor(partner: string) {
  if (partner === "mydpcrx") {
    return "hsl(210, 100%, 32.9%)"
  } else if (partner === "firstprimarycare" || partner === "clearprice" || partner === "rx4free") {
    return "hsl(18, 76.4%, 50.2%)"
  } else if (partner === "bettyhealth") {
    return "hsl(216, 61.2%, 74.7%)"
  } else {
    return "hsl(6.5, 76.4%, 50.2%)"
  }
}

function searchButtonHoverColor(partner: string) {
  if (partner === "mydpcrx") {
    return "hsl(210, 100%, 27.9%)"
  } else if (partner === "firstprimarycare" || partner === "clearprice" || partner === "rx4free") {
    return "hsl(18, 76.4%, 45.2%)"
  } else if (partner === "bettyhealth") {
    return "hsl(216, 61.2%, 69.7%)"
  } else {
    return "hsl(6.5, 76.4%, 45.2%)"
  }
}

// hsl(6.5, 76.4%, 50.2%)
// hsl(18, 76.4%, 50.2%) for FPC and RX4Free and Clear Price
// hsl(216, 61.2%, 74.7%) for Betty
const SearchButton = styled(InvertedButton.withComponent("button"))<{ partner: string }>`
  border-radius: 1.4rem;
  background-color: ${p => searchButtonColor(p.partner)};
  border-color: ${p => searchButtonColor(p.partner)};
  font-family: Rubik, sans-serif;
  font-size: 1.3em;
  cursor: pointer;
  height: 100%;

  &:hover {
    background-color: ${p => searchButtonHoverColor(p.partner)};
    border-color: ${p => searchButtonHoverColor(p.partner)};
    color: white !important;
  }
`;

export const useStore = create<SearchState>(
  devtools(
    immer((set, get) => ({
      text: "",
      loading: false,
      updateQuery: (text: string) => {
        set({ text });
        get().refreshOptions();
      },
      refreshOptions: debounce(async () => {
        if (/\S\S\S+/.test(get().text)) {
          try {
            set({ loading: true });
            const partner = getPartner();
            const searchText = get().text;

           const query = gql`
              query AutoCompleteSearchSearch {
                search(partner: ${partner}, drugName: "${searchText}", count: 5) {
                  displayName,
                  ndc,
                  seoName
              }
            }`;

            const client = new GraphQLClient(API_URL + "/graphql");

            const result = await client.request(query, {
              partner,
              drugName: get().text,
            });

            if (result?.search.length > 0) {
              set({ options: Ok(result.search), loading: false });
            } else {
              set({
                options: Err({
                  name: "Failed Request",
                  message: "failed request",
                }),
                loading: false,
              });
            }
          } catch (err) {
            set({ options: Err(err), loading: false });
          }
        } else {
          set({ options: undefined });
        }
      }, 250),
    })),
  ),
);

export interface Props {
  onSelect?: (option: SearchOption) => void;
}

const Container = styled.div`
  display: grid;
  align-items: center;
  width: 100%;
  min-width: min(80vw, 60em);
  grid-template-rows: 1fr max-content;
  grid-gap: 0.5em;

  @media (min-width: 1024px) {
    grid-gap: 1em;
    grid-template-columns: 1fr max-content;
    grid-template-rows: unset;
  }

  .MuiOutlinedInput-root {
    border-radius: 1.4rem;
    border: 1px solid var(--main-color);

    &:hover {
      border-color: black;
    }

    &:focus-within {
      border-color: hsl(194.3, 80.2%, 35.7%);
    }

    .MuiOutlinedInput-notchedOutline {
      display: none !important;
    }
  }

  .MuiInputAdornment-root > p {
    font-family: "Font Awesome 5 Pro" !important;
  }
`;

export function SearchBox(props: Props) {
  const text = useStore((state) => state.text);
  const setText = useStore((state) => state.updateQuery);
  const loading = useStore((state) => state.loading);
  const rawOptions = useStore((state) => state.options);
  const options = rawOptions?.unwrapOr([]) || [];
  const isSpanish = checkSpanish();

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        options={options}
        open={options.length > 0}
        clearOnBlur={false}
        disableClearable
        forcePopupIcon={false}
        loading={loading}
        inputValue={text}
        autoHighlight
        popupIcon={<></>}
        onInputChange={(_, newValue) => setText(newValue)}
        getOptionLabel={(option) => option.displayName}
        onChange={(_, newValue, reason) =>
          reason === "select-option" && props.onSelect(newValue)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            margin="none"
            placeholder={isSpanish ? "Ingrese el nombre del medicamento" : "Enter medication name"}
            aria-label="Enter medication name"
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
              type: "search",
              notched: false,
              style: {
                backgroundColor: "white",
                backgroundClip: "padding-box",
              },
            }}
          />
        )}
      />
    </ThemeProvider>
  );
}

export default function Search(props: Props) {
  const rawOptions = useStore((state) => state.options);
  const options: SearchOption[] = rawOptions?.unwrapOr([]) || [];
  const partnerName = getPartner();
  const isSpanish = checkSpanish();

  return (
    <Container>
      <SearchBox {...props} />
      <SearchButton
        onClick={() => options.length > 0 && props.onSelect(options[0])} partner={partnerName}
      >
        {isSpanish ? "Encuentra los precios más bajos" : "Find the lowest prices"}
      </SearchButton>
    </Container>
  );
}

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  align-content: center;
  justify-items: end;
  height: 100%;
  margin-right: 0.5em;
`;

const HeaderBoxContainer = styled.div`
  display: none;
  float: right;
  /* hack to work around delay in hiding the logo. React doesn't control the logo so the effect happens on a slight delay. */
  transition: display 0s linear 0.1s;

  @media (min-width: 1024px) {
    min-width: 20em;
    display: block;
    margin-left: 4em;
  }

  .MuiOutlinedInput-root {
    border-radius: 2rem;
    border: 1px solid #ccc;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &:hover {
      border-color: black;
    }

    &:focus-within {
      border-color: hsl(194.3, 80.2%, 35.7%);
    }

    .MuiOutlinedInput-notchedOutline {
      display: none !important;
    }
  }

  .MuiInputAdornment-root > p {
    font-family: "Font Awesome 5 Pro" !important;
  }
`;

const ToggleButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  font-family: "Font Awesome 5 Pro";
  font-size: 1.4rem;
  color: #1d6379;
  margin-left: 0.5rem;

  @media (min-width: 1024px) {
    display: none;
  }
`;

injectGlobal`
  @media(max-width: 1024px) {
    #headerNav .spacer {
      display: none;
    }
    #header-search-box { flex-grow: 1; }

    #headerLogo {
      &.hidden {
        display: none;
      }
    }
  }
`;

const ToggleSearch = ({ expanded, toggle }) => (
  <ToggleButton onClick={toggle}>{!expanded ? "" : ""}</ToggleButton>
);

export function HeaderSearch(props: Props) {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (expanded) {
      document.getElementById("headerLogo").classList.add("hidden");
    } else {
      document.getElementById("headerLogo").classList.remove("hidden");
    }

    return () =>
      document.getElementById("headerLogo").classList.remove("hidden");
  }, [expanded]);

  const toggle = useMemo(
    () => () => setExpanded(!expanded),
    [setExpanded, expanded],
  );

  return (
    <HeaderContainer>
      <HeaderBoxContainer
        style={
          expanded
            ? {
                display: "block",
                width: "20em",
                marginLeft: "-1em",
                maxWidth: "65vw",
              }
            : {}
        }
      >
        <SearchBox {...props} />
      </HeaderBoxContainer>
      <ToggleSearch expanded={expanded} toggle={toggle} />
    </HeaderContainer>
  );
}
