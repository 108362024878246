import "preact/compat";
import styled from "@emotion/styled";

export const colors = {
  green: "var(--main-color)",
  grey: "hsl(232.3, 14.8%, 59%)",
};

export const Button = styled.a`
  appearance: none;
  text-decoration: none;
  background: none;
  color: ${colors.green} !important;
  border: 1px solid ${colors.green};
  border-radius: calc(1em + 0.6em);
  padding: 0.5em 0.8em;
  font-size: 1.2em;
  text-align: center;

  &:hover[href] {
    text-decoration: none !important;
    color: white !important;
    background: ${colors.green};
  }

  &:disabled,
  &:hover:disabled {
    color: ${colors.grey} !important;
    border-color: ${colors.grey};
    background: #eee;
  }
`;

export const InvertedButton = styled(Button)`
  color: white !important;
  background: ${colors.green};

  &:hover {
    color: ${colors.green} !important;
    background: white;
  }
`;
