import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main:
        process.env.JEST_WORKER_ID !== undefined
          ? "rgb(13, 93, 118)"
          : getComputedStyle(document.documentElement)
              .getPropertyValue("--main-color")
              .trim(),
    },
  },
});

export default theme;
